.slick-slider {
  height: 100%;
}
.slide-content {
  height: 100%;
}
.slide-content .slick-slide div {
  height: 100%;
}
.slick-list {
  height: 100% !important;
}
.slick-track {
  height: 100% !important;
}
